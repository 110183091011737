import React, { useEffect, useState } from 'react';
import './Intro.css';
import { Link } from "react-router-dom";

export default function Intro() {
    const [isTextVisible, setIsTextVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTextVisible(true);
        }, 300); // 300ms delay

        return () => clearTimeout(timer);
    }, []);

    return (
        <section className='intro'>
            <div className={`intro__text ${isTextVisible ? 'visible' : ''}`}>
                <h1 className='intro__title'>Unique Plateforme Européenne d'Épargne en Matériaux Critiques</h1>
                <h2 className='intro__description'>{`Diversifiez votre patrimoine avec\ndes métaux sous forme physique`}</h2>
                <Link target="_blank" to="/contact-us">
                    <button className='intro__button'>Contactez-nous</button>
                </Link>
                <p className='intro__mention'>{`Ce site est un site de trading et stockage, nous ne conseillons pas la spéculation, ce n'est pas notre métier`}</p>
            </div>
            <div className={`intro__picture ${isTextVisible ? 'visible' : ''}`}></div>
        </section>
    );
}
