import {React, useState, useEffect} from "react";
import './Insights.css';
import InsightsCard from '../InsightsCard/InsightsCard';
import {insights} from '../../utils/utils'
import {Helmet} from 'react-helmet-async'
import image from "../../images/logo_new.jpg"
import {articlesSchema} from "../../utils/constants/schema"
import Full from "../../images/articlesview/full"
import ListIcon from "../../images/articlesview/list"
import frontendInsights from "../../utils/utils"
import {URL} from '../../utils/constants/constants'

export default function Insights() {
    const [isMore, setMore] = useState(3);
    const [isNews, setNews] = useState('all');
    const [isIcons, setIcons] = useState(0)
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    
    
useEffect(() => {
    // GET request using fetch inside useEffect React hook
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    fetch(`${URL}/articles`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(article => {
            setArticles(article)
            setIsLoading(false)
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        });
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);


  
    if (error) {
        return <div className="error-container">Error: {error}</div>; // Display error message
    }
  

    function filterInsights (insight) {
        if (isNews === 'all'){
            return insight
        }else if (isNews === 'news'){
            return insight.type === 'actualité';
        }else if (isNews === 'insights'){
            return insight.type === 'analyse'
        }
    }

    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('.');
        return new Date(year, month - 1, day); // Month is zero-based in JavaScript Date
    };
    
    const combinedArticles = [...articles, ...frontendInsights].sort((a, b) => {
        return parseDate(b.year) - parseDate(a.year); // Sort in descending order by date
        // If you want to sort in ascending order, swap the order: `parseDate(a.date) - parseDate(b.date)`
    });

    // const combinedArticles = [...articles, ...frontendInsights];

    const addMoreButton = () => {
        if(combinedArticles.filter(filterInsights).length > isMore)
        return <button className='insights__more-button' onClick={() => setMore(isMore+6)}>Plus d'articles</button> 
        else 
        return null
    } 

    // console.log(combinedArticles)
    return (
        <>
            <Helmet>
                <title>Le Marché</title>
                <meta name="description" content="Voyez nos actualités et analyses sur les marchés des métaux critiques." />
                <link rel="canonical" href="https://wesafe.store/articles" />
    
                <meta property="og:url" content={`https://wesafe.store/articles`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Le Marché" />
                <meta property="og:description" content="Voyez nos actualités et analyses sur les marchés des métaux critiques." />
                <meta property="og:image" content={image} />
    
                <meta name="twitter:card" content="summary" />
                {/* <meta name="twitter:site" content="@yourusername" /> */}
                <meta name="twitter:title" content="Le Marché" />
                <meta name="twitter:description" content="Voyez nos actualités et analyses sur les marchés des métaux critiques." />
                <meta name="twitter:image" content={image} />
                <script type="application/ld+json">
                    {JSON.stringify(articlesSchema)}
                </script>
            </Helmet>
            <section className="insights">
                <div className="insights__topic">
                    <h1 className="insights__title">Actualités & Analyses</h1>
                    <h2 className="insights__subtitle">Suivez les dernières mises à jour sur le marché avec nous</h2>
                </div>
    
                {isLoading ? (
                    <div className="insights__loading">Chargement en cours...</div> // Display loading state
                ) : error ? (
                    <div className="insights__loading">Erreur: {error}</div> // Display error message
                ) : (
                    <div className='insights__container'>
                        <div className='insights__button-container'>
                            <div className='insights__buttons'>
                                <button className={`insights__button ${isNews === 'all' ? 'insights__button_active' : 'insights_button_inactive'}`} onClick={() => setNews('all')}>Tout</button>
                                <button className={`insights__button ${isNews === 'insights' ? 'insights__button_active' : 'insights_button_inactive'}`} onClick={() => setNews('insights')}>Analyses</button>
                                <button className={`insights__button ${isNews === 'news' ? 'insights__button_active' : 'insights_button_inactive'}`} onClick={() => setNews('news')}>Actualités</button>
                            </div>
                            <div className="insights__views">
                                <Full color={`${isIcons === 0 ? "#646060" : "#001F3D"}`} bccolor="white" onClick={() => setIcons(0)} />
                                <ListIcon color={`${isIcons === 1 ? "#646060" : "#001F3D"}`} bccolor="white" onClick={() => setIcons(1)} />
                            </div>
                        </div>
                        <div className='insights__map'>
                            {combinedArticles.filter(filterInsights).map((article, index) => {
                                if (isMore > index) {
                                    return <InsightsCard key={article.name} isIcons={isIcons} article={article} />;
                                }
                                return null;
                            })}
                        </div>
                    </div>
                )}
                {addMoreButton()}
            </section>
        </>
    );
    
}