import {React, useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";
import linkedin from '../../images/linkedin_blue.png';

function Navigation({handleActiveClick, isShowMenu, setIsShowMenu}) {
    const location = useLocation(); // Get the current location
    const [openMetals, setOpenMetals] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(false); // Track if a sub-menu item is active
    const [active, setActive] = useState(""); // Track the active link

    useEffect(() => {
        // Check current location and set the active link accordingly
        if (location.pathname.includes("/copper") || location.pathname.includes("/nickel") || location.pathname.includes("/cobalt")) {
            setActive("2"); // Nos Metaux and its submenu items
            setActiveSubMenu(true); // Underline Nos Metaux when on submenu pages
        } else if (location.pathname === "/articles") {
            setActive("3"); // Le Marché
        } else if (location.pathname === "/wesafe-the-world") {
            setActive("4"); // WeSafe The World
        } else if (location.pathname === "/about-us") {
            setActive("5"); // Qui sommes-nous?
        } else if (location.pathname === "/contact-us") {
            setActive("6"); // Contactez-nous
        } else {
            setActive(""); // Default no active link
            setActiveSubMenu(false);
        }
    }, [location]); // Run this effect whenever the URL changes

    const handleToggleMetals = (e) => {
        setOpenMetals(!openMetals);
        e.preventDefault();
    }

    const handleCloseBurgerMenu = () => {
        return isShowMenu ? setIsShowMenu(false) : null;
    }

    const handleCloseMetalsBurgerMenu = (e) => {
        return isShowMenu ? handleToggleMetals(e) : null;
    }

    // Handles submenu item clicks and underlines Nos Metaux
    const handleSubMenuClick = (e) => {
        handleActiveClick(e); // Calls the active click handler
        setActive("2"); // Set "Nos Metaux" as active when a submenu item is clicked
        setActiveSubMenu(true); // Set "Nos Metaux" underline when a submenu item is clicked
        handleCloseBurgerMenu(); // Close the burger menu if necessary
    }

    // Handles general navigation clicks and deactivates the Nos Metaux underline
    const handleNavigationClick = (e) => {
        handleActiveClick(e); // Handle active click logic for other items
        setActive(e.target.id); // Set the clicked item as active
        setActiveSubMenu(false); // Remove underline from Nos Metaux when another item is clicked
        handleCloseBurgerMenu(); // Close the burger menu if necessary
    }

    return (
        <>
            <button 
                className={`navigation__link navigation__link_main navigation__link_button 
                ${!openMetals ? 'navigation__button-dropped' : 'navigation__button-undropped'} 
                ${activeSubMenu ? 'navigation__link_active' : 'navigation__link_inactive'}`} 
                onMouseEnter={handleToggleMetals} 
                onMouseLeave={handleToggleMetals} 
                onClick={handleCloseMetalsBurgerMenu}
            >
                Nos Metaux&nbsp;&nbsp;&nbsp;
                <nav className={`navigation__dropdown ${openMetals ? 'open' : ''}`}>
                    <ul className="navigation__dropdown-list">
                        <li className="navigation__dropdown-item">
                            <button className='navigation__dropdown-button' 
                                onClick={(e) => {setOpenMetals(false); handleSubMenuClick(e);}}
                            >
                                <Link id={"2"} className="navigation__link navigation__dropdown-link" to="/copper">
                                    Cuivre
                                </Link>
                            </button>
                        </li>
                        <li className="navigation__dropdown-item">
                            <button className='navigation__dropdown-button' 
                                onClick={(e) => {setOpenMetals(false); handleSubMenuClick(e);}}
                            >
                                <Link id={"2"} className="navigation__link navigation__dropdown-link" to="/nickel">
                                    Nickel
                                </Link>
                            </button>
                        </li>
                        <li className="navigation__dropdown-item">
                            <button className='navigation__dropdown-button' 
                                onClick={(e) => {setOpenMetals(false); handleSubMenuClick(e);}}
                            >
                                <Link id={"2"} className="navigation__link navigation__dropdown-link" to="/cobalt">
                                    Cobalt
                                </Link>
                            </button>
                        </li>
                    </ul>
                </nav>
            </button>

            {/* Other links */}
            <Link 
                id={"3"} 
                className={`navigation__link navigation__link_main ${active === "3" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/articles"
            >
                Le Marché
            </Link>

            <Link 
                id={"4"} 
                className={`navigation__link navigation__link_main ${active === "4" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/wesafe-the-world"
            >
                WeSafe The World
            </Link>

            <Link 
                id={"5"} 
                className={`navigation__link navigation__link_main ${active === "5" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/about-us"
            >
                Qui sommes-nous?
            </Link>

            <Link 
                id={"6"} 
                className={`navigation__link navigation__link_main ${active === "6" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/contact-us"
            >
                Contactez-nous
            </Link>

            {/* Social media and contact information */}
            <div className="navigation__network">
                <p className="navigation__phone">+33 (0) 1 83 64 66 18</p>
                <Link className="navigation__linkedin-link" to={"https://www.linkedin.com/company/wesafe-store/"} target="_blank">
                    <img className="navigation__linkedin-logo" src={linkedin} alt="linkedin" />
                </Link>
            </div>
        </>
    );
}

export default Navigation;
