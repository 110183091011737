const ListIcon = ({color, bccolor, onClick}) => (
<svg className='insights__view' width="80" height="80" viewBox="0 0 80 80" onClick={onClick} style={{ fill: bccolor }} xmlns="http://www.w3.org/2000/svg">
{/* <rect width="40" height="40" fill="none"/> */}
<circle cx="19" cy="21" r="5" style={{ fill: color }}/>
<circle cx="19" cy="21" r="5" style={{ fill: color }}/>
<circle cx="19" cy="38" r="5" style={{ fill: color }}/>
<circle cx="19" cy="56" r="5" style={{ fill: color }}/>
<rect x="30" y="16" width="36" height="8" rx="4" style={{ fill: color }}/>
<rect x="30" y="34" width="36" height="8" rx="4" style={{ fill: color }}/>
<rect x="30" y="52" width="36" height="8" rx="4" style={{ fill: color }}/>
</svg>);

export default ListIcon
